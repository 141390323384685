import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
let scalesRegistered = false

function registerScalesOnce () {
    if (!scalesRegistered) {
        Chart.register(...registerables, annotationPlugin, ChartDataLabels, FunnelController, TrapezoidElement)
        scalesRegistered = true
    }
}

// Call this function at the entry point of your application to register the scales once.
registerScalesOnce()
// Now all scales are registered and available for use in your charts.

// Global defaults

// these defaults don't work because plugins are empty to begin with.
// upon testing, assigning defaults will not work on the react system.
// to remedy this, export these instead and then apply them to the chart
// of your choosing.

// NOTE: looks like legend.labels isn't being applied IF you have labels
// on your property assignment on the chart you're making. Use a spread operator.

// NOW, unused.
// export const DEFAULT_CHART_OPTIONS: PluginOptionsByType<keyof ChartTypeRegistry> = {
//     legend: {
//         labels: {
//             boxHeight: 5,
//             boxWidth: 10,
//             padding: 15
//         }
//     } as LegendOptions<keyof ChartTypeRegistry>,
//     title: {
//         padding: 10,
//         display: true
//     } as TitleOptions,
//     subtitle: {
//         font: {
//             size: 32
//         }
//     } as TitleOptions,
//     tooltip: {
//         xAlign: 'center',
//         yAlign: 'bottom',
//         position: 'nearest',
//         usePointStyle: false,
//         displayColors: false
//     } as TooltipOptions<keyof ChartTypeRegistry>,
//     colors: {} as ColorsPluginOptions,
//     decimation: {} as DecimationOptions,
//     filler: {} as FillerOptions,
//     annotations: {} as _DeepPartialObject<AnnotationPluginOptions>
// }
