import { GetReadinessAdvisoryResponse } from '@doc/type'

interface ComponentProps {
    readinessAdvisoryData: GetReadinessAdvisoryResponse['data'] | undefined
}

const MarkdownTextField = ({ readinessAdvisoryData } : ComponentProps) => {
    return <div className={'course-interface h-100'} >
        <div className={'container'}>
            <div className={'row  align-items-center advice-container'}>
                <div className={'col-auto'}>
                    <i className={'fas fa-stethoscope'}></i>
                </div>
                <div className={'col flex-column'}>
                    <div className={'title'}>{
                        readinessAdvisoryData?.advisoryData.header
                    }</div>
                    <div className={'subtitle'}>{
                    }</div>
                </div>
            </div>
        </div>

        <div className={'mb-2'}>
            <span className={'d-inline-block fw-lighter mb-1 mt-3'}>
                { readinessAdvisoryData?.advisoryData.body}
            </span>
        </div>
    </div>
}

export default MarkdownTextField
