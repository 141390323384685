import { MODULE_TABLE } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { push } from '@lagunovsky/redux-react-router'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import _ from 'lodash'
import { ReactNode } from 'react'

interface NavigationContainerProps {
  children: ReactNode;
}

const NavigationContainer = ({ children }: NavigationContainerProps) => {
    return <div className={'navigation-container'}>{children}</div>
}

interface NavigationProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Navigation = ({ activeTab, setActiveTab }: NavigationProps) => {
    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const tabs = [
        strings.doc?.text.careprofessionals.dashboard_navigation.careprofessionals,
        strings.doc?.text.careprofessionals.dashboard_navigation.pt_readiness
        // 'management tab',
        // 'director tab'
    ]

    const NavigationTabs = _.map(tabs, (o, key) => {
        const isActive = activeTab === o

        return (
            <div key={key} className={`navigation-tab ${ isActive ? 'active-tab' : '' }`}
                onClick={() => {
                    if (o === strings.doc?.text.careprofessionals.dashboard_navigation.careprofessionals) {
                    // set the id and go to a new screen.
                        const isValid = validateRoute(
                            activeModules.arr,
                            MODULE_TABLE.doc.moduleName,
                            MODULE_TABLE.doc.routes.careprofessionalDashboard,
                            true
                        )

                        dispatch(push(isValid.route))
                    } else if (o === strings.doc?.text.careprofessionals.dashboard_navigation.pt_readiness) {
                        const isValid = validateRoute(
                            activeModules.arr,
                            MODULE_TABLE.doc.moduleName,
                            MODULE_TABLE.doc.routes.ptReadinessDashboard,
                            true
                        )

                        dispatch(push(isValid.route))
                    }
                }}>
                {o}
            </div>
        )
    })

    return (
        <NavigationContainer>
            {NavigationTabs}
        </NavigationContainer>
    )
}

export default Navigation
