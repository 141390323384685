import { MODULE_TABLE } from '@app/app.config'

/** This is the name of the module. */
export const MODULE_NAME = {
    LONG: MODULE_TABLE.careplanPatient.moduleName,
    SHORT: MODULE_TABLE.careplanPatient.moduleShortName
}

/** This is the major version number of the module. */
export const MODULE_MAJOR_VERSION = 1

/** This is the minor version number of the module. */
export const MODULE_MINOR_VERSION = 0

/** This is the hotfix version number of the module. */
export const MODULE_HOTFIX_VERSION = 9

/** This is the full version string of the module,
 * based on the major, minor, and hotfix versions. */
export const MODULE_VERSION = [
    MODULE_NAME.SHORT, ' ',
    `v.${ MODULE_MAJOR_VERSION }.${ MODULE_MINOR_VERSION }.${ MODULE_HOTFIX_VERSION }`
].join('')
// should be 10 seconds. this should also be the same increment with updating
// the active timers in all elements.
export const UPDATE_PROGRESS_INTERVAL = 10000
// export const UPDATE_PROGRESS_INTERVAL = 60000

// check careplan status interval
export const CHECK_CAREPLAN_STATUS_INTERVAL = 3000

export const SEEKER = {
    MIN: 0,
    MAX: 0.999999,
    VALUE: 5
}

// should be a range of 100
export const VOLUME = {
    MIN: 0,
    MAX: 1,
    VALUE: 0.001
}

export const IDS = {
    LIBRARY: {
        START: 'CAREPLAN__START_DATE',
        END: 'CAREPLAN__END_DATE',
        SEARCH: 'CAREPLAN__SEARCH'
    }
}

export const PROGRESS_CIRCLES = [
    {
        id: 2,
        size: 60,
        animationOff: true,
        linearGradient: ['#47c0a3', '#55e7c5'],
        round: true,
        percent: 0,
        colorCircle: 'rgba(198, 204, 210, 0.5)',
        number: false,
        icon: 'fa-graduation-cap',
        gradient: 'bg-gradient-module',
        textType: 'text-module',
        count: '2',
        item: 'modules',
        strokeWidth: 15
    },
    {
        id: 3,
        size: 60,
        animationOff: true,
        linearGradient: ['#5e0098', '#8300d3'],
        round: true,
        percent: 0,
        colorCircle: 'rgba(198, 204, 210, 0.5)',
        number: false,
        icon: 'fa-wave-pulse',
        gradient: 'bg-gradient-activity',
        textType: 'text-activity',
        count: '3',
        item: 'activities',
        strokeWidth: 15
    },
    {
        id: 4,
        size: 60,
        animationOff: true,
        linearGradient: ['#003b70', '#0672d3'],
        round: true,
        percent: 0,
        colorCircle: 'rgba(198, 204, 210, 0.5)',
        number: false,
        icon: 'fa-book',
        gradient: 'bg-gradient-article',
        textType: 'text-article',
        count: '4',
        item: 'articles',
        strokeWidth: 15
    }
]
