import { useAppSelector } from '@app/app.hook'
import { selectStylesheets } from '@stylesheet/slice'
import {
    Chart,
    ChartData,
    ChartOptions,
    LegendOptions,
    TitleOptions,
    TooltipOptions
} from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Context } from 'chartjs-plugin-datalabels'
import { Options } from 'chartjs-plugin-datalabels/types/options'
import { useEffect, useRef } from 'react'

import { selectToken } from '@app/slices/slice.token'
import {
    GetPatientChartDataResponse,
    GraphInfo,
    HealthRelatedReducerActions,
    HealthRelatedReducerState,
    LineDatasetIndexType
} from '@doc/type'
import { ADMIN_COLORS as IKH_ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { ADMIN_COLORS as RTW_ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'
import { fromUnixTime } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import nl from 'date-fns/locale/nl'
import _ from 'lodash'

/** two bugs to fix. change the font styles of the ticks.
 * when you hover over the edge data points, the tooltip either gets cropped out
 * or the tooltip keeps line breaking.
 */

/**
 * A simple component that creates and renders a Chart.js bar chart. IS IT?!
 */

type ActiveChartType = Chart<'line', LineDatasetIndexType[]>;

interface ComponentProps {
    level: 'main' | 'primary'
    patientChartData: GetPatientChartDataResponse['data']
    detailsReducerState: HealthRelatedReducerState
    detailsReducerDispatch:React.Dispatch<HealthRelatedReducerActions>
    parentChartInfo: GraphInfo
}

const LineChart = ({
    level, patientChartData,
    detailsReducerState, detailsReducerDispatch,
    parentChartInfo
}: ComponentProps) => {
    // Reference to the canvas element where the chart will be rendered
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    // Store the chart instance in a ref for later access and manipulation
    const chartInstanceRef = useRef<ActiveChartType | null>(null)

    const stylesheets = useAppSelector(selectStylesheets)
    const token = useAppSelector(selectToken)

    useEffect(() => {
        // Data and options for the chart

        let borderColor = ''
        let pointBorderColor = ''
        let fontColor = ''
        let pointBackgroundColor = {
            last: '',
            notLast: ''
        }
        let predictionBgColor = ''
        let dataLabelColors = {
            bgColor: {
                default: ''
            },
            fontColor: ''
        }

        // sheet to use.
        let sheetToUse = IKH_ADMIN_COLORS

        if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
            sheetToUse = IKH_ADMIN_COLORS
        } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
            sheetToUse = RTW_ADMIN_COLORS
        }

        borderColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.dark)
            : (sheetToUse.white)
        pointBorderColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.dark)
            : (sheetToUse.white)
        pointBackgroundColor = {
            last: stylesheets.admin?.theme === 'light'
                ? (sheetToUse.white)
                : (sheetToUse.dark),
            notLast: stylesheets.admin?.theme === 'light'
                ? (sheetToUse.dark)
                : (sheetToUse.white)
        }
        fontColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.dark)
            : (sheetToUse.white)
        predictionBgColor = stylesheets.admin?.theme === 'light'
            ? (sheetToUse.darkOpacityLow)
            : (sheetToUse.whiteOpacityLow)
        dataLabelColors = {
            bgColor: {
                default: stylesheets.admin?.theme === 'light'
                    ? sheetToUse.dark
                    : (sheetToUse.white)
            },
            fontColor: stylesheets.admin?.theme === 'light'
                ? sheetToUse.white
                : (sheetToUse.dark)
        }

        const data: ChartData<'line', LineDatasetIndexType[], Date> = {
            datasets: [
                {
                    label: 'base line',
                    data: _.map(patientChartData.graphData, (obj) => {
                        return {
                            x: fromUnixTime(obj.timestamp),
                            y: obj.dataPoint,
                            extraInfo: {
                                graphId: parentChartInfo.graphId
                            }
                        }
                    }),
                    borderColor,
                    tension: 0.4,
                    pointBackgroundColor: pointBackgroundColor.notLast,
                    pointRadius: 4,
                    pointBorderColor,
                    pointBorderWidth: 0,
                    datalabels: {
                        display: false
                    }
                },
                {
                    label: 'upper ceiling',
                    data: _.map(patientChartData.graphData, (obj) => {
                        return {
                            x: fromUnixTime(obj.timestamp),
                            y: obj.dataPoint + (obj.confidenceSpread * 100)
                        }
                    }),
                    borderColor: 'transparent',
                    tension: 0.4,
                    pointStyle: false,
                    datalabels: {
                        display: false
                    }
                },
                {
                    label: 'lower ceiling',
                    data: _.map(patientChartData.graphData, (obj) => {
                        return {
                            x: fromUnixTime(obj.timestamp),
                            y: obj.dataPoint + (obj.confidenceSpread * 100 * -1)
                        }
                    }),
                    borderColor: 'transparent',
                    tension: 0.4,
                    backgroundColor: predictionBgColor,
                    fill: '-1',
                    pointStyle: false,
                    datalabels: {
                        display: false
                    }
                }
            ]
        }

        let locale: Locale = enUS

        if (_.lowerCase(token.details.locale) === 'nl-nl') {
            locale = nl
        } else if (_.lowerCase(token.details.locale) === 'en-us') {
            locale = enUS
        } else {
            locale = enUS
        }

        const legendPlugin: | _DeepPartialObject<LegendOptions<'line'>> | undefined = {
            labels: {
                boxHeight: 5,
                boxWidth: 10,
                color: fontColor,
                padding: 15
            },
            display: false
        }

        const titlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            color: fontColor,
            font: {
                size: 16,
                weight: 'normal' as number | 'normal' | 'bold' | 'lighter' | 'bolder'
            },
            padding: 10,
            display: true

        }

        const subtitlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            font: {
                size: 32
            }
        }

        const tooltipPlugin: | _DeepPartialObject<TooltipOptions<'line'>>| undefined = {
            enabled: true
        }

        const dataLabelsPlugin: _DeepPartialObject<Options> | undefined = {
            backgroundColor: function (ctx: Context) {
                return (ctx.dataset.backgroundColor || dataLabelColors.bgColor.default) as string
            },
            borderRadius: 100,
            color: dataLabelColors.fontColor,
            font: {
                weight: 'bold'
            },
            padding: {
                top: 4,
                right: 8,
                left: 8,
                bottom: 2
            },
            formatter (value: LineDatasetIndexType, context) {
                return value.y
            },
            offset: 8,
            align: 'top',
            anchor: 'end'
        }

        // console.log('Patient Chart Data: ', patientChartData)

        const options: ChartOptions<'line'> = {
            responsive: true,
            maintainAspectRatio: false,
            datasets: {
                line: {
                    borderWidth: 2
                }
            },
            plugins: {
                legend: legendPlugin,
                title: titlePlugin,
                subtitle: subtitlePlugin,
                tooltip: tooltipPlugin,
                datalabels: dataLabelsPlugin
            },
            interaction: {
                intersect: false,
                mode: 'index'
            },
            onClick (event, elements, chart) {
                if (elements.length > 0) {
                    const clickedDataPoint = elements[0]

                    const data = chart.data
                        .datasets[clickedDataPoint.datasetIndex]
                        .data[clickedDataPoint.index] as unknown as LineDatasetIndexType

                    // after accessing data, we can show the popup modal now
                    // and set this to the useState

                    if (level === 'main') {
                        console.log('primary modal set with: ', data)
                        // pass graph id here.
                        detailsReducerDispatch({
                            type: 'SET_PRIMARY_MODAL',
                            value: data
                        })
                    } else if (level === 'primary') {
                        if (!detailsReducerState.secondaryContent) {
                            console.log('secondary modal set with: ', data)
                            // pass graph id here.
                            detailsReducerDispatch({
                                type: 'SET_SECONDARY_CONTENT',
                                value: data
                            })
                        } else {
                            // don't show anything because you are in secondary already.
                            console.log('reached end level')
                        }
                    }
                }
            },
            scales: {
                x: {
                    adapters: {
                        date: {
                            locale
                        }
                    },
                    display: true,
                    type: 'time',
                    time: {
                        // leaving unit undefined for flexibility
                        // and letting chartjs do its thing.
                        displayFormats: {
                            month: 'MMM yyyy'
                        }
                    },
                    grid: {
                        display: false
                    },
                    ticks: {
                        color: fontColor,
                        font: {
                            family: 'Ikh-Admin',
                            weight: 'lighter'
                        },
                        source: 'data'
                    }
                },
                y: {
                    grid: {
                        display: true
                    },
                    ticks: {
                        stepSize: patientChartData.graphLimits.stepSize,
                        callback: function (value, index, values) {
                            return value + patientChartData.graphLimits.graphSuffix
                        },
                        color: fontColor,
                        font: {
                            family: 'Ikh-Admin',
                            weight: 'lighter'

                        }
                    },
                    min: patientChartData.graphLimits.lowerLimit,
                    max: patientChartData.graphLimits.upperLimit

                }
            },
            font: {
                family: 'Ikh-Admin',
                size: 12
            }
        }

        // Create the chart when the component mounts
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')

            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'line',
                    data,
                    options
                })
            }
        }

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
            }
        }
    }, [stylesheets, token.details.locale, patientChartData])

    return <canvas ref={canvasRef} />
}

export default LineChart
