import { useAppSelector } from '@app/app.hook'
import { ADMIN_COLORS as IKH_ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { ADMIN_COLORS as RTW_ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'
import { selectStylesheets } from '@stylesheet/slice'
import {
    Chart,
    ChartOptions,
    LegendOptions,
    TitleOptions,
    TooltipOptions,
    TooltipPosition
} from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Options } from 'chartjs-plugin-datalabels/types/options'
import { useEffect, useRef } from 'react'

import { selectToken } from '@app/slices/slice.token'
import { FunnelData, GetReadinessFunnelResponse, ReadinessTableActions } from '@doc/type'
import Color from 'color'
import _ from 'lodash'

type ActiveChartType = Chart<'funnel', number[]>;

interface ComponentProps {
    getReadinessFunnelResponse: GetReadinessFunnelResponse | undefined
    readinessTableDispatch: React.Dispatch<ReadinessTableActions>
}

const CONTAINER_HEIGHT = 750

const generateGradientColors = (data: FunnelData[], colorRange1: string, colorRange2: string) => {
    const startColor = Color(colorRange1) // Starting color (orange)
    const endColor = Color(colorRange2) // Ending color (dark)

    return data.map((_, index) => {
        const ratio = index / (data.length - 1) // Calculate position in gradient (0 to 1)
        return startColor.mix(endColor, ratio).hex() // Generate the color
    })
}

const clearTooltip = (chart: ActiveChartType | null) => {
    const tooltip = chart?.tooltip

    if (!tooltip) return

    // Clear active elements and reset tooltip position
    tooltip.setActiveElements([], {
        x: 0,
        y: 0
    })

    chart.update() // Update the chart to remove the tooltip display
}

const triggerTooltip = (chart: ActiveChartType | null, datasetIndex: number, dataIndex: number): void => {
    const tooltip = chart?.tooltip

    if (!tooltip) return

    if (tooltip.getActiveElements().length > 0) {
        // Clear active elements
        tooltip.setActiveElements([], {
            x: 0,
            y: 0
        })
    } else {
        const chartArea = chart.chartArea
        const tooltipPosition: TooltipPosition = {
            x: (chartArea.left + chartArea.right) / 2,
            y: (chartArea.top + chartArea.bottom) / 2
        }

        // Set active elements for the specified dataset and data point
        tooltip.setActiveElements(
            [
                {
                    datasetIndex,
                    index: dataIndex
                }
            ],
            tooltipPosition
        )
    }

    chart.update()
}

const Blocks = ({ readinessTableDispatch, data, colors, chartInstanceRef }: {
    readinessTableDispatch: React.Dispatch<ReadinessTableActions>
    colors: string[],
    data: FunnelData[],
    chartInstanceRef: React.MutableRefObject<ActiveChartType | null>
}) => {
    return (
        <div className={'d-flex flex-column'} style={{
            height: `${ CONTAINER_HEIGHT }px`,
            gap: '2px',
            cursor: 'pointer'
        }}>
            {data.map((value, index) => {
                const heightPercentage = CONTAINER_HEIGHT / data.length // Scale heights
                return (
                    <div
                        key={index}
                        className={'d-flex justify-content-center align-items-center'}
                        style={{
                            height: `${ heightPercentage }px`,
                            backgroundColor: colors[index],
                            color: 'white'
                        }}
                        onClick={() => {
                            readinessTableDispatch({
                                type: 'SET_SELECTED',
                                value
                            })
                        }}
                        onMouseEnter={() => {
                            triggerTooltip(
                                chartInstanceRef.current,
                                0, index
                            )
                        }}
                        onMouseLeave={() => {
                            clearTooltip(chartInstanceRef.current)
                        }}
                    >
                        {value.funnelValue.absolute}
                    </div>
                )
            })}
        </div>
    )
}

// we just need the data
const FunnelChart = ({ getReadinessFunnelResponse, readinessTableDispatch }: ComponentProps) => {
    // Reference to the canvas element where the chart will be rendered
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    // Store the chart instance in a ref for later access and manipulation
    const chartInstanceRef = useRef<ActiveChartType | null>(null)

    const stylesheets = useAppSelector(selectStylesheets)
    const token = useAppSelector(selectToken)

    let sheetToUse = IKH_ADMIN_COLORS

    const data = _.cloneDeep(getReadinessFunnelResponse?.data.readinessData || [])

    if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
        sheetToUse = IKH_ADMIN_COLORS
    } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
        sheetToUse = RTW_ADMIN_COLORS
    }

    const colors = generateGradientColors(
        data,
        sheetToUse.orange, sheetToUse.blue
    )

    useEffect(() => {
        // Data and options for the chart

        // let borderColor = ''
        // let backgroundColor = ''
        // let pointBorderColor = ''
        // let pointBackgroundColor = ''

        // sheet to use.
        // let sheetToUse = IKH_ADMIN_COLORS

        // if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
        //     sheetToUse = IKH_ADMIN_COLORS
        // } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
        //     sheetToUse = RTW_ADMIN_COLORS
        // }

        // let colorToUse = sheetToUse.green

        // colorToUse = sheetToUse.green

        // backgroundColor = Color(colorToUse).alpha(0.2).rgb().toString()
        // borderColor = Color(colorToUse).alpha(0.5).rgb().toString()
        // pointBorderColor = colorToUse
        // pointBackgroundColor = colorToUse

        const legendPlugin: | _DeepPartialObject<LegendOptions<'funnel'>> | undefined = {
            display: false
        }

        const titlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false

        }

        const subtitlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false
        }

        const tooltipPlugin: | _DeepPartialObject<TooltipOptions<'funnel'>>| undefined = {
            enabled: true
        }

        const dataLabelsPlugin: _DeepPartialObject<Options> | undefined = {
            display: true
        }

        // sorted and mapped.
        // Step 1: Sort by funnelState
        const sortedFunnelData = data.sort((a, b) => a.funnelState - b.funnelState)
        // you need labels or else the chart won't show.
        const labels: string[] = sortedFunnelData.map((item) =>
            ['Step', item.funnelValue.absolute].join(' ')
        )

        // Step 2: Map percentages
        const percentageArray = sortedFunnelData.map((item) => item.funnelValue.percentage)

        const options: ChartOptions<'funnel'> = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: legendPlugin,
                title: titlePlugin,
                subtitle: subtitlePlugin,
                tooltip: tooltipPlugin,
                datalabels: dataLabelsPlugin

            },
            layout: {
                autoPadding: false
            },
            indexAxis: 'y',
            onClick (event, elements, chart) {
                console.log(event.native)

                if (elements.length > 0) {
                    const clickedDataPoint = elements[0]

                    // get data from sorted values.
                    const data = sortedFunnelData[clickedDataPoint.index]

                    // console.log('clicked data point: ', data)
                    // make dispatch
                    readinessTableDispatch({
                        type: 'SET_SELECTED',
                        value: data
                    })
                }
            }
        }

        // Create the chart when the component mounts
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')

            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'funnel',
                    data: {
                        labels,
                        datasets: [
                            {
                                label: 'Patient Score',
                                data: percentageArray,
                                datalabels: {
                                    formatter: (v) => [v.toLocaleString(), '%'].join('')
                                },
                                backgroundColor: colors,
                                shrinkAnchor: 'bottom'
                            }
                        ]
                    },
                    options
                })
            }
        }

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
            }
        }
    }, [stylesheets, token.details.locale, getReadinessFunnelResponse])

    return <div className={'readiness-interface'} style={{
        boxSizing: 'content-box',
        height: CONTAINER_HEIGHT
    }}>
        <div className={'row'}>
            <div className={'col-3'}><Blocks chartInstanceRef={chartInstanceRef} colors={colors} data={data} readinessTableDispatch={readinessTableDispatch}/></div>
            <div className={'col-9'}><canvas className={'chart-bordered clickable'}
                ref={canvasRef} /></div>
        </div>
    </div>
}

export default FunnelChart
