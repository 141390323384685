
import { Filter } from '@app/types/type.app'
import { SerializedError } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

import {
    CARE_FACILITY_CUSTOM_MATCH,
    CAREPATH_CUSTOM_MATCH,
    CAREPLAN_STEP_CUSTOM_MATCH,
    CAREPLANS_CUSTOM_MATCH,
    CAREPROFESSIONAL_CUSTOM_MATCH,
    CONTENT_CUSTOM_MATCH,
    DEPARTMENT_CUSTOM_MATCH,
    EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE,
    EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE,
    LIBRARY_ARTICLE_CUSTOM_MATCH,
    MODULE_TABLE,
    PATIENT_CUSTOM_MATCH,
    REGISTRATION_CUSTOM_MATCH,
    TREATMENT_CUSTOM_MATCH
} from '@app/app.config'
import { ModuleApiPath, Route, SystemModule } from '@login/type'
import flatten from 'flat'
import _, { Dictionary } from 'lodash'

export const getErrorText = (error?: FetchBaseQueryError | SerializedError) => {
    if (!error) {
        return 'No error'
    }

    if ('status' in error) {
        return `Fetch error: ${ error.status }`
    } else if ('message' in error) {
        return `Serialized error: ${ error.message }`
    } else {
        return `Unknown error: ${ error }`
    }
}

// methods shared by the entire system
/**
 * @function tryParseJSON - method for parsing JSON string objects.
 * @param {string} jsonString - Should be a JSON stringified value.
 * @return an Object. would become falsy afterward through a key length check
 * */
export const tryParseJSON: (jsonString: string) => Record<string, unknown> =
(jsonString: string) => {
    try {
        // console.log(jsonString)
        // Replace single quotes with double quotes
        const correctedJSONString = jsonString.replace(/'/g, '"')
        // console.log(correctedJSONString)
        const obj = JSON.parse(correctedJSONString)
        // console.log(obj)
        if (obj && typeof obj === 'object') {
            return obj as Record<string, unknown>
        }
    } catch (e) {}

    return {}
}

export const findModule: (
        config: SystemModule[], module: string
    ) => SystemModule | undefined = (config, module) => {
        const found = _.find(config, (e) => e.moduleName === module)
        return found
    }

export const findModuleAll: (
        config: SystemModule[], module: string
    ) => SystemModule | undefined = (config, module) => {
        const foundModule = _.find(config, (e) => e.moduleId === module)
        return foundModule
    }

export const findRoute: (
        config: SystemModule[], module: string, route: string
    ) => Route | undefined = (config, module, route) => {
        const found = _.find(config, (e) => e.moduleName === module)
        const foundRoute = _.find(found?.feRoutes, (e) =>
            e.route === route
        )
        return foundRoute
    }

// this would fail if there is a route in 2 or more modules that have the same route path.
// as the first instance would be recognized. but design decisions assures that there shouldn't
// be any duplicate routes.
export const getUniqueRoutes = (arr: SystemModule[]): Route[] => {
    return _.uniqBy(_.flatMap(arr, (obj) => obj.feRoutes), 'route')
}

export const findRouteAll: (
    config: SystemModule[], route: string
) => Route | undefined = (config, route) => {
    const allRoutes = getUniqueRoutes(config)

    // console.log(allRoutes)
    // include regex routes here.
    const foundRoute = _.find(allRoutes, (e) => {
        const regV1Check = () => {
            if (e.route === MODULE_TABLE
                .registration.routes.registrationByEmail) {
                return REGISTRATION_CUSTOM_MATCH.test(route)
            }
            return false
        }

        const logV1Check = () => {
            if (e.route === MODULE_TABLE
                .login.routes.loginByEmail) {
                return EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE
                    .test(route) || EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE
                    .test(route)
            }
            return false
        }

        // careplan/62ab28709296b300b7196174
        const careplansV1Check = () => {
            if (e.route === MODULE_TABLE.careplanPatient
                .routes.careplan) {
                return CAREPLANS_CUSTOM_MATCH.test(route)
            }
            return false
        }

        const careplanStepV1Check = () => {
            if (e.route === MODULE_TABLE.careplanPatient
                .routes.careplanStep) {
                return CAREPLAN_STEP_CUSTOM_MATCH.test(route)
            }
            return false
        }

        const contentV1Check = () => {
            if (e.route === MODULE_TABLE.profile
                .routes.content) {
                return CONTENT_CUSTOM_MATCH.test(route)
            }
            return false
        }

        const libraryArticleV1Check = () => {
            if (e.route === MODULE_TABLE.library
                .routes.article) {
                return LIBRARY_ARTICLE_CUSTOM_MATCH.test(route)
            }
            return false
        }

        const careFacilitiesV1Check = () => {
            if (e.route === MODULE_TABLE.fmt

                .routes.editCareFacility) {
                return CARE_FACILITY_CUSTOM_MATCH.test(route) && route.includes('edit')
            } else if (e.route === MODULE_TABLE.fmt

                .routes.viewCareFacility) {
                return CARE_FACILITY_CUSTOM_MATCH.test(route)
            } else if (e.route === MODULE_TABLE.fmt

                .routes.createCareFacility) {
                return CARE_FACILITY_CUSTOM_MATCH.test(route) && route.includes('add')
            }

            return false
        }

        const departmentsV1Check = () => {
            if (e.route === MODULE_TABLE.fmt

                .routes.editDepartment) {
                return DEPARTMENT_CUSTOM_MATCH.test(route) && route.includes('edit')
            } else if (e.route === MODULE_TABLE.fmt

                .routes.viewDepartment) {
                return DEPARTMENT_CUSTOM_MATCH.test(route)
            } else if (e.route === MODULE_TABLE.fmt

                .routes.createDepartment) {
                return DEPARTMENT_CUSTOM_MATCH.test(route) && route.includes('add')
            }

            return false
        }

        const careprofessionalsV1Check = () => {
            if (e.route === MODULE_TABLE.fmt

                .routes.editCareprofessional) {
                return CAREPROFESSIONAL_CUSTOM_MATCH.test(route) && route.includes('edit')
            } else if (e.route === MODULE_TABLE.fmt

                .routes.viewCareprofessional) {
                return CAREPROFESSIONAL_CUSTOM_MATCH.test(route)
            } else if (e.route === MODULE_TABLE.fmt

                .routes.createCareprofessional) {
                return CAREPROFESSIONAL_CUSTOM_MATCH.test(route) && route.includes('add')
            }

            return false
        }

        const treatmentsV1Check = () => {
            if (e.route === MODULE_TABLE.fmt

                .routes.editTreatment) {
                return TREATMENT_CUSTOM_MATCH.test(route) && route.includes('edit')
            } else if (e.route === MODULE_TABLE.fmt

                .routes.viewTreatment) {
                return TREATMENT_CUSTOM_MATCH.test(route)
            } else if (e.route === MODULE_TABLE.fmt

                .routes.createTreatment) {
                return TREATMENT_CUSTOM_MATCH.test(route) && route.includes('add')
            }

            return false
        }

        const carepathsV1Check = () => {
            if (e.route === MODULE_TABLE.fmt

                .routes.editCarepath) {
                return CAREPATH_CUSTOM_MATCH.test(route) && route.includes('edit')
            } else if (e.route === MODULE_TABLE.fmt

                .routes.viewCarepath) {
                return CAREPATH_CUSTOM_MATCH.test(route)
            } else if (e.route === MODULE_TABLE.fmt

                .routes.createCarepath) {
                return CAREPATH_CUSTOM_MATCH.test(route) && route.includes('add')
            }

            return false
        }

        const patientsV1Check = () => {
            if (e.route === MODULE_TABLE.doc

                .routes.editPatient) {
                return PATIENT_CUSTOM_MATCH.test(route) && route.includes('edit')
            } else if (e.route === MODULE_TABLE.doc

                .routes.viewPatient) {
                return PATIENT_CUSTOM_MATCH.test(route) && route.includes('view')
            } else if (e.route === MODULE_TABLE.doc

                .routes.createPatient) {
                return PATIENT_CUSTOM_MATCH.test(route) && route.includes('add')
            } else if (e.route === MODULE_TABLE.doc

                .routes.ptIndividual) {
                return PATIENT_CUSTOM_MATCH.test(route) && route.includes('ptReadiness')
            }

            return false
        }

        return (e.route === route) || regV1Check() || logV1Check() ||
         careplansV1Check() || careplanStepV1Check() ||
         contentV1Check() || libraryArticleV1Check() || careFacilitiesV1Check() ||
         departmentsV1Check() || careprofessionalsV1Check() || treatmentsV1Check() ||
         carepathsV1Check() || patientsV1Check()
    })
    return foundRoute
}

// for apiPaths with extra parameters, we'll make a separate function for that.
export const findAPIPath: (
    config: SystemModule[], module: string, apiPath: string
) => ModuleApiPath | undefined = (config, module, apiPath) => {
    const found = _.find(config, (e) => e.moduleName === module)
    const apiExists = _.find(found?.apiPaths, (e) => e.apiPath === apiPath)
    return apiExists
}

/**
 * @function smartSearch - client side search method for now until
 * we have access to backend to create a server-side query with pagination, sorting
 * and filtering similar to Kibana Lucene query
 * @param tableData - usually an array of objects but regular arrays work too.
 * @param filters - to filter results based on this value after search string filtering
 * @param search - uses _.some to return a truthy result
 * @returns - filtered tableData
 */
export const smartSearch: (
    tableData: unknown[],
    filters: Filter[],
    search: string
) => unknown[] = (
    tableData, filters, search
) => {
    /**
         * first thing to do is filter the results via string.
         * iterate tableData
         */
    const result = _.filter(tableData, (tableDataObj) => {
        // then iterate over the object's values.

        /**
             * before doing the searches, to take care of nested arrays and objects
             * use the npm flat function.
             * */

        /** we have to lowercase both values to be compared. use regexp operator */

        const flattenedObj = flatten(tableDataObj) as Dictionary<unknown>

        /**
             * do a _.includes (SameValueZero search). the commented out code
             * didn't work since we are expecting a regExp kind of search
             *
             * did _.some AND _.method for a substring search. _.method
             * uses match and a regExp expression as parameters
             */
        // const searchFound = _.includes(flattenedObj, search)
        const searchFound = _.some(
            flattenedObj,
            _.method('match', new RegExp(search, 'ig'))
        )
        // console.log('search string found result: ', searchFound)

        /**
             * then perform a filters iteration to filter at a time.
             * the main goal is to meet ALL the current needs of the
             * filters. it's either an array of values or one to use
             * _.includes as above.
             *
             * IF there aren't any filters, then the data would be empty.
             * Perform a filters.length check to prevent this
             */

        const filterMatches = _.map(filters, (filterObj, _index) => {
            let match = false

            /** if filterObj.sort is NOT truthy, find in all properties */
            if (!filterObj.sort) {
                /** checks the object if it strictly has the string.
                     */
                match = _.includes(flattenedObj, filterObj.value)
                /**
                     * could be loose but that would also return results where
                     * the filter value can be a substring
                     * */

                // match = _.some(
                //     flattenedObj,
                //     _.method('match', new RegExp(filterObj.value, 'ig'))
                // )

                /** if truthy, let's check if the value of
                     * that property even exists in the object. Strict equality is used.
                     * To deal with NESTED object properties that have been flattened,
                     * when adding that filter, specify the sort string as if it was
                     * flattened. You can see some examples in event/dashboard modules.
                     *
                     * UPDATE form September 11, 2022. You can compare stringified numbers
                     * with actual numbers.
                     *
                     * If no results pop out, kindly check your filterObj.sort value. It
                     * should be strictly compared to FLATTENED keys. Kindly navigate
                     * to AzureDetails.tsx zoomAction method on conditional statements.
                     *  */
            } else if (_.has(flattenedObj, filterObj.sort)) {
                match = _.includes(
                    filterObj.value.toString(),
                    ((flattenedObj[filterObj.sort]) as any).toString()
                )
            }

            /** and if filterObj.not is true, get the reverse results. */
            return filterObj.not ? !match : match
        })

        // console.log('is filter array empty: ', filters.length)
        // console.log('search filters result: ', filterMatches)

        /**
             * the following cases should return a possible truthy condition
             * if search string returns TRUTHY, return true and proceed to filter check
             * otherwise, return false
             * in the array of filters, make sure ALL of the elements are true booleans
             * */
        return searchFound
            ? (filters.length
                ? !_.includes(filterMatches, false)
                : true
            )
            : false
    })

    // console.log('smartSearch result: ', result)

    return result
}

/**
 * Calculates the estimated reading time in milliseconds for a given paragraph
 * @param paragraph - The string paragraph to calculate reading time for
 * @returns The estimated reading time in milliseconds
 */
export const readTime: (paragraph: string) => number = (paragraph) => {
    if (!paragraph.trim()) {
        return 0
    }
    const words: string[] = paragraph.split(/\s+/).filter(Boolean)
    const wordCount: number = words.length
    // const readTimeMilliseconds: number = wordCount / 200 * 60 * 1000
    const readTimeMilliseconds: number = wordCount / 380 * 60 * 1000
    return readTimeMilliseconds
}

// Define the suppressError function. if there's an error that you wanted to see
// but doesn't show up because something doesn't work, you can consider going back to this.
export const suppressError = () => {
    const consoleError = console.error
    const SUPPRESSED_WARNINGS = [
        'inputref',
        'Invalid prop `nodeRef` of type `function` supplied to `t`, expected `object`.'
    ]

    console.error = function filterErrorings (msg, ...args) {
        // console.info('error msg to show: ', msg)
        // console.info('error args to show: ', args)
        // console.info(SUPPRESSED_WARNINGS)
        if (!SUPPRESSED_WARNINGS.some((entry) => args.includes(entry))) {
            consoleError(msg, ...args)
        }
    }

    return null
}

export function checkRoute (config: SystemModule[], route: string): 'admin' | 'enduser' | 'none' {
    const moduleGroups: Record<'admin' | 'enduser' | 'none', string[]> = {
        enduser: ['reasonWithMe', 'careplanPatient', 'login', 'registration', 'profile', 'library'],
        admin: ['fmt', 'doc'],
        none: []
    }

    const found = findRouteAll(config, route)

    const moduleName = _.findKey(
        MODULE_TABLE, (module) => {
            if (found?.route) {
                return _.values(module.routes).includes(found?.route)
            } else {
                return false
            }
        }
    )

    // console.log('moduleName result: ', moduleName)

    if (moduleName) {
        const group = _.findKey(
            moduleGroups, (groupModules) => _.includes(groupModules, moduleName)
        )
        return (group || 'enduser') as ('admin' | 'enduser' | 'none')
    }

    return 'enduser' // Default to "enduser" if route doesn't match any module or group
}

export function getModuleGroupName (config: SystemModule[], route: string): string {
    const moduleName = _.findKey(
        MODULE_TABLE, (module) => {
            const found = findRouteAll(config, route)
            if (found?.route) {
                return _.values(module.routes).includes(found?.route)
            } else {
                return false
            }
        }
    )

    return moduleName || ''
}

export const returnToLinkRouteCheck: (route: string) => boolean = (route) => {
    const moduleName = _.findKey(
        MODULE_TABLE, (module) => {
            return _.values(module.routes).includes(route)
        }
    )

    // console.log(route, moduleName)
    // return false on any conditions that shouldn't do anything.
    if (
        REGISTRATION_CUSTOM_MATCH.test(route) ||
        Object.values(MODULE_TABLE.registration.routes).includes(route)
    ) {
        // console.log("not setting returnToLink because it's a reg link")
        return false
    } else if (
        EMAIL_LOGIN_CUSTOM_MATCH_WITH_ROUTE.test(route) ||
        EMAIL_LOGIN_CUSTOM_MATCH_WITHOUT_ROUTE.test(route)
    ) {
        // console.log("not setting returnToLink because it's a login quick link")
        return false
    } else if (
        Object.values(MODULE_TABLE.login.routes).includes(route)
    ) {
        // console.log('not setting login module routes')
        return false
    } else if (
    // new else if detected. don't set returnToLink the rwm paths beacuse if you do,
    // you'll back to it again after making a careplan which means you are stuck in a loop.
        Object.values(MODULE_TABLE.reasonWithMe.routes).includes(route)
    ) {
        // console.log('not setting rwm module routes')
        return false
    } else if (!moduleName) {
        // console.log('route does not exist')
        return false
    }

    // if you return true here, you should be doing the setToReturnLink dispatch.
    return true
}

export const getAllRoutes: () => string[] = () => {
    const allRoutes: string[] = []

    for (const moduleName in MODULE_TABLE) {
        const str = moduleName as keyof typeof MODULE_TABLE
        const module = MODULE_TABLE[str]
        if (module.routes) {
            allRoutes.push(..._.values(module.routes))
        }
    }

    return allRoutes
}
