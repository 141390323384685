import { MODULE_TABLE } from '@app/app.config'
import { TOASTIFY_DEFAULT_OPTIONS } from '@app/app.constants'
import { useAppSelector } from '@app/app.hook'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { selectToken } from '@app/slices/slice.token'
import { TokenData } from '@app/types/type.token'
import { useRevalidateToken } from '@login/MutationProvider/revalidateToken'
import { useValidateAPIPath } from '@login/MutationProvider/validateAPIPath'

import { useGetPatientChartsMutation } from '@doc/api'
import DetailedHealthRelatedChartInterface from '@doc/components/patients/details/components/DetailedHealthRelatedChartInterface'
import {
    GraphInfo,
    HealthRelatedReducerActions,
    HealthRelatedReducerState,
    PatientParams
} from '@doc/type'
import _ from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ComponentProps {
    level: 'main' | 'primary',
    detailsReducerState: HealthRelatedReducerState
    detailsReducerDispatch:React.Dispatch<HealthRelatedReducerActions>
    primaryChartData: GraphInfo[] | undefined
    dateStates: {
        startDate: Date | null,
        endDate: Date | null
    }
}

const HealthRelatedModal = (
    {
        level,
        detailsReducerState,
        detailsReducerDispatch,
        primaryChartData,
        dateStates
    }:ComponentProps
) => {
    const revalidateToken = useRevalidateToken()
    const validateAPIPath = useValidateAPIPath()
    const activeModules = useAppSelector(selectActiveModules)
    const token = useAppSelector(selectToken)
    const strings = useAppSelector(selectStrings)

    const { userId } = useParams<PatientParams>()

    // make the call after clicking on the secondary data point.
    const [getSecondaryData, getSecondaryDataMutation] = useGetPatientChartsMutation()

    const secondaryData = getSecondaryDataMutation.data?.data

    const fetchSecondaryData = (token: TokenData) => {
        let isMounted = true

        const call = async () => {
            if (
                token.valid &&
                detailsReducerState.secondaryContent?.extraInfo?.graphId &&
                userId
            ) {
                const newToken = await revalidateToken({
                    value: token.value,
                    id: token.id
                }, token.mode)
                if (isMounted) {
                    const isValid = validateAPIPath(
                        activeModules.arr,
                        MODULE_TABLE.doc.moduleName,
                        MODULE_TABLE.doc.apiPaths
                            .getPatientChartData.path,
                        true
                    )

                    if (isValid && newToken.value) {
                        getSecondaryData({
                            authToken: newToken.value,
                            data: {
                                userId,
                                // don't use generalChartInfo.graphId. that's the main
                                // chart info. use the id from the selected chart.
                                parentId: detailsReducerState.secondaryContent?.extraInfo?.graphId,
                                graphGroup: 'predict'
                            }
                        })
                    }
                }
            }
        }

        call()

        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        if (detailsReducerState.secondaryContent) {
            fetchSecondaryData(token)
        }
    }, [detailsReducerState.secondaryContent])

    const primaryContent = <div className={'row g-3'}>
        {
            _.map(
                _.orderBy(primaryChartData, 'sortOrder', 'asc'),
                (obj, index) => {
                    const key = `chart-primary-${ obj.graphId }-index`

                    return <div key={key} className={[
                        obj.colSpan
                    ].join(' ')}>
                        <DetailedHealthRelatedChartInterface
                            level={level} parentChartInfo={obj}
                            detailsReducerState={detailsReducerState}
                            detailsReducerDispatch={detailsReducerDispatch}
                            dateStates={dateStates}
                            getSecondaryDataIsLoading={getSecondaryDataMutation.isLoading}
                        />
                    </div>
                })
        }
    </div>

    useEffect(() => {
        if (getSecondaryDataMutation.data) {
            const arr = getSecondaryDataMutation.data.data

            if (!arr.length) {
                toast.error(
                    strings.doc?.message.error?.no_charts || '',
                    { ...TOASTIFY_DEFAULT_OPTIONS })
            }
        }
    }, [getSecondaryDataMutation.data])

    const secondaryContent = <div className={'row g-3'}>
        {
            _.map(
                _.orderBy(secondaryData, 'sortOrder', 'asc'),
                (obj, index) => {
                    const key = `chart-secondary-${ obj.graphId }-index`

                    return <div key={key} className={[
                        obj.colSpan
                    ].join(' ')}>
                        <DetailedHealthRelatedChartInterface
                            level={level} parentChartInfo={obj}
                            detailsReducerState={detailsReducerState}
                            detailsReducerDispatch={detailsReducerDispatch}
                            dateStates={dateStates}
                        />
                    </div>
                })
        }
    </div>

    return <>
        {
            getSecondaryDataMutation.data?.data.length ? secondaryContent : primaryContent
        }
    </>
}

export default HealthRelatedModal
