import { useAppDispatch, useAppSelector } from '@app/app.hook'
import Pagination from '@app/components/Pagination'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { ADMIN_COLORS as IKH_ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { ADMIN_COLORS as RTW_ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'

import { MODULE_TABLE } from '@app/app.config'
import { selectToken } from '@app/slices/slice.token'
import { CAREPROF_TABLE_LIMIT } from '@doc/constants'
import { GetReadinessTableResponse, ReadinessTableActions, ReadinessTableState } from '@doc/type'
import { push } from '@lagunovsky/redux-react-router'
import { useValidateRoute } from '@login/MutationProvider/validateRoute'
import Color from 'color'
import _ from 'lodash'
import React from 'react'
import { DebouncedState } from 'use-debounce'

interface ComponentProps {
    debouncedFetchReadinessTable: DebouncedState<() => void>
    rootRef: React.MutableRefObject<HTMLDivElement | null>
    getReadinessTableResponse: {
        isLoading: boolean;
        isSuccess: boolean;
        response: GetReadinessTableResponse | undefined;
        readinessTableState: ReadinessTableState
        readinessTableDispatch: React.Dispatch<ReadinessTableActions>
    }

}

// It gets a list of readinessTable reported by the system in the last 7 days

const ReadinessTable = ({
    debouncedFetchReadinessTable,
    rootRef,
    getReadinessTableResponse
}: ComponentProps) => {
    const validateRoute = useValidateRoute()
    const activeModules = useAppSelector(selectActiveModules)
    const dispatch = useAppDispatch()
    const strings = useAppSelector(selectStrings)

    const token = useAppSelector(selectToken)

    let sheetToUse = IKH_ADMIN_COLORS

    if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
        sheetToUse = IKH_ADMIN_COLORS
    } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
        sheetToUse = RTW_ADMIN_COLORS
    }

    const isTableEmpty = <small className={'d-block text-center py-2'}>
        <span >{
            strings.app?.message.error.empty_table || ''
        }</span>
    </small>

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    const tableContent =

        <tbody>
            {
                getReadinessTableResponse.response
                    ?.data.readinessData.length
                    ? _.map(getReadinessTableResponse.response
                        ?.data.readinessData, (obj) => {
                        const key = obj.userId

                        const borderColor = Color(sheetToUse.orange)
                            .mix(Color(sheetToUse.blue), obj.readinessScore / 100)
                            .hex() // Return the color as hex

                        return <React.Fragment key={key}>
                            <tr onClick={() => {}}>
                                <td className={[
                                    'risk-border'
                                ].join(' ')} style={{
                                    borderLeftColor: borderColor
                                }}>
                                    <u className={'me-2 clickable'} onClick={() => {
                                        // set the id and go to a new screen.
                                        const isValid = validateRoute(
                                            activeModules.arr,
                                            MODULE_TABLE.doc.moduleName,
                                            MODULE_TABLE.doc.routes.ptIndividual,
                                            true
                                        )

                                        dispatch(push(
                                            _.replace(isValid.route,
                                                ':userId',
                                                obj.userId
                                            )
                                        ))
                                    }}>{
                                            obj.name
                                        }</u>
                                </td>
                                <td>
                                    <span className={'me-2'}>{obj.readinessScore}</span>
                                </td>
                            </tr>
                        </React.Fragment>
                    })
                    : <tr><td colSpan={5}>{isTableEmpty}</td></tr>
            }
        </tbody>

    return <div className={'readiness-interface h-100'} ref={rootRef}>
        <div className={'row align-items-center justify-content-between mb-4 mt-2'}>
            <div className={'col-auto'}>
                <h6 className={'mb-0 fw-semibold '} >
                    {strings.doc?.text.careprofessionals.dashboard_navigation.pt_readiness}
                </h6>
            </div>
            <div className={'col-auto'}>
                {/* refresh button */}
                <div className={'icon d-inline-block clickable'} onClick={() => {
                    if (getReadinessTableResponse.isLoading === false) {
                        debouncedFetchReadinessTable()
                    }
                }}>
                    <i className={'fa-light fa-rotate-right'} aria-hidden={'true'}></i>
                </div>
            </div>
        </div>
        {/* now the table */}
        <div className={[
            'record-menu',
            (getReadinessTableResponse.response
                ?.data.totalRecords || 1) <= (getReadinessTableResponse.response
                ?.data.limit || CAREPROF_TABLE_LIMIT)
                ? 'h-auto'
                : ''
        ].join(' ')}>

            <table>
                <thead>
                    <tr>
                        <th>
                            <span className={'me-2'}>
                                {strings.doc?.text.careprofessionals
                                    .abnormalities_table.name}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                        <th>
                            <span className={'me-2'}>{strings.doc?.text.careprofessionals.pt_readiness.score}</span>
                            <i className={'fa-solid fa-angles-up-down'}></i>
                        </th>
                    </tr>
                </thead>
                {
                    getReadinessTableResponse.isLoading
                        ? <tbody><tr><td colSpan={5}>{LoadingContent}</td></tr></tbody>
                        : tableContent
                }
            </table>

        </div>

        {
            (getReadinessTableResponse.response
                ?.data.totalRecords || 1) > (getReadinessTableResponse.response
                ?.data.limit || CAREPROF_TABLE_LIMIT) && (
                <div className={'container-fluid pb-4 pt-6 px-3'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-auto'}>
                            <Pagination
                                currentPage={getReadinessTableResponse
                                    .readinessTableState.skip}
                                setCurrentPageDispatch={(value: number) => {
                                    getReadinessTableResponse.readinessTableDispatch({
                                        type: 'SET_SKIP',
                                        value
                                    })
                                }}
                                limit={getReadinessTableResponse.response
                                    ?.data.limit || getReadinessTableResponse.response
                                    ?.data.totalRecords || 1}
                                skip={getReadinessTableResponse.response
                                    ?.data.skip || 0}
                                totalRecords={(getReadinessTableResponse.response
                                    ?.data.totalRecords || 1)}
                            />
                        </div>
                    </div>
                </div>
            )
        }

    </div>
}

export default ReadinessTable
