import { MODULE_TABLE, PATIENT_CUSTOM_MATCH } from '@app/app.config'
import { useAppDispatch, useAppSelector } from '@app/app.hook'
import { selectRouter } from '@app/app.store'
import { selectActiveModules, selectStrings } from '@app/slices/slice.app'
import { useValidateRouteAll } from '@login/MutationProvider/validateRouteAll'

import { PatientParams } from '@doc/type'
import { push } from '@lagunovsky/redux-react-router'
import _ from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface NavigationContainerProps {
  children: ReactNode;
}

const NavigationContainer = ({ children }: NavigationContainerProps) => {
    return <div className={'navigation-container'}>{children}</div>
}

interface NavigationProps {
  activeTab: {
        name: string | undefined;
        link: string;
    } | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<{
        name: string | undefined;
        link: string;
    } | undefined>>
}

const Navigation = ({ activeTab, setActiveTab }: NavigationProps) => {
    const strings = useAppSelector(selectStrings)

    const router = useAppSelector(selectRouter)
    const validateRouteAll = useValidateRouteAll()
    const activeModules = useAppSelector(selectActiveModules)
    const dispatch = useAppDispatch()
    const { userId } = useParams<PatientParams>()

    const [tabs] = useState([
        {
            name: strings.doc?.text.patient.patient_navigation.patient_records,
            link: MODULE_TABLE.doc.routes.editPatient
        },
        {
            name: strings.doc?.text.patient.patient_navigation.ikherstel,
            link: MODULE_TABLE.doc.routes.viewPatient
        },
        {
            name: strings.doc?.text.careprofessionals.dashboard_navigation.pt_individual_patient,
            link: MODULE_TABLE.doc.routes.ptIndividual
        }
    ])

    useEffect(() => {
        // on mount, select the group where the link matches with the
        // router.location.pathname

        // console.log('link:', router.location.pathname)

        const found = _.find(tabs, (o) => {
            if (
                o.link === MODULE_TABLE.doc.routes.editPatient
            ) {
                const regexMatch = PATIENT_CUSTOM_MATCH.test(router.location.pathname)
                const substrMatch = router.location.pathname.includes('edit')
                return regexMatch && substrMatch
            } else if (

                o.link === MODULE_TABLE.doc.routes.viewPatient
            ) {
                const regexMatch = PATIENT_CUSTOM_MATCH.test(router.location.pathname)
                const substrMatch = router.location.pathname.includes('view')

                return regexMatch && substrMatch
            } else if (

                o.link === MODULE_TABLE.doc.routes.ptIndividual
            ) {
                const regexMatch = PATIENT_CUSTOM_MATCH.test(router.location.pathname)
                const substrMatch = router.location.pathname.includes('ptReadiness')

                return regexMatch && substrMatch
            } else {
                return router.location.pathname === o.link
            }
        })

        // console.log('Found:', found)
        found && (setActiveTab(found))
    }, [router.location.pathname, tabs])

    const linkNavigation = (link: string) => {
        // now dispatch to another page.
        const foundRoute = validateRouteAll(
            activeModules.arr, link, false
        )
        if (foundRoute) {
            // if the link clicked is MODULE_TABLE.doc.routes.careplan
            // then you need to use the link with the personalCareplanId
            if (link === MODULE_TABLE.doc.routes.editPatient ||
                link === MODULE_TABLE.doc.routes.viewPatient ||
                link === MODULE_TABLE.doc.routes.ptIndividual
            ) {
                dispatch(push(
                    _.replace(
                        foundRoute.route, ':userId',
                        userId || ''
                    )
                ))
            } else {
                dispatch(push(foundRoute.route))
            }
        }
    }

    const NavigationTabs = _.map(tabs, (o, key) => {
        const isActive = activeTab === o

        return (
            <div key={key} className={`navigation-tab ${ isActive ? 'active-tab' : '' }`}
                onClick={() => linkNavigation(o.link)}>
                {o.name}
            </div>
        )
    })

    return (
        <NavigationContainer>
            {NavigationTabs}
        </NavigationContainer>
    )
}

export default Navigation
