import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import HealthRelatedChartInterface from '@doc/components/careprofessionalsDashboard/readinessIndividual/HealthRelatedChartInterface'
import MarkdownTextField from '@doc/components/careprofessionalsDashboard/readinessIndividual/MarkdownTextField'
import RadarChart from '@doc/components/careprofessionalsDashboard/readinessIndividual/RadarChart'
import { GetPatientReadinessSpiderResponse, GraphInfo } from '@doc/type'
import _ from 'lodash'

interface ComponentProps {
    floatingUiBoundary: HTMLDivElement | null,
    patientReadinessAdvisoryData: {
        isLoading: boolean
        isSuccess: boolean
        data: {
            advisoryData: {
                header: string;
                body: string;
            };
        } | undefined
    }
    patientChartData: GraphInfo[]
    getPatientReadinessSpiderResponse: {
        isLoading: boolean
        isSuccess: boolean
        response: GetPatientReadinessSpiderResponse | undefined
    }
}

const ReadinessIndividualMain = (
    {
        floatingUiBoundary,
        patientReadinessAdvisoryData,
        patientChartData, getPatientReadinessSpiderResponse
    }:ComponentProps
) => {
    const strings = useAppSelector(selectStrings)

    const charts = <div className={'container-fluid px-0'}>
        <div className={'my-5 row g-3'}>
            {
                _.map(
                    _.orderBy(patientChartData, 'sortOrder', 'asc'),
                    (obj, index) => {
                        const key = `chart-main-${ obj.graphId }-index`

                        return <div key={key} className={[
                            obj.colSpan
                        ].join(' ')}>
                            <HealthRelatedChartInterface level={'main'} generalChartInfo={obj}
                                floatingUiBoundary={floatingUiBoundary}
                            />
                        </div>
                    })
            }
        </div>
    </div>

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    return <div>
        <div className={'row g-3 mb-5'}>
            <div className={'col-6'}>
                {
                    getPatientReadinessSpiderResponse.isLoading
                        ? LoadingContent
                        : getPatientReadinessSpiderResponse.isSuccess
                            ? <RadarChart getPatientReadinessSpiderResponse={getPatientReadinessSpiderResponse.response}/>
                            : ''
                }
            </div>
            <div className={'col-6'}>
                {
                    patientReadinessAdvisoryData.isLoading
                        ? LoadingContent
                        : patientReadinessAdvisoryData.isSuccess
                            ? <MarkdownTextField readinessAdvisoryData={patientReadinessAdvisoryData.data}/>
                            : ''
                }
            </div>
            <div className={'col-12'}>
                {charts}
            </div>
        </div>
    </div>
}

export default ReadinessIndividualMain
