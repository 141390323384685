import { MODULE_TABLE } from '@app/app.config'

import _ from 'lodash'

import { SystemModule } from '@login/type'
import React from 'react'

import CareProfessionalsDashboard from '@doc/components/careprofessionalsDashboard/DashboardMain'
import PatientDetailsInterface from '@doc/components/patients/details/PatientDetailsInterface'
import PatientsMain from '@doc/components/patients/PatientsMain'

interface ComponentProps {
    config: SystemModule[]
}
const selectedModule = MODULE_TABLE.doc
/** The final array of route configurations with their corresponding components */
const Routes = ({ config }: ComponentProps) => {
    const arr: { moduleName: string, path: string,
            element: React.ReactNode
        }[] = []

    const found = _.find(config, (o) => {
        return o.moduleName === selectedModule.moduleName
    })

    if (found) {
        _.forEach(_.cloneDeep(found.feRoutes), (o) => {
            let element: React.ReactNode | undefined

            // a new variable named route to pass in so we can cater to routes
            // with parameters on them.
            let routeName = ''
            switch (o.route) {
                case selectedModule.routes.patients:
                case selectedModule.routes.createPatient: {
                    element = <PatientsMain/>
                    routeName = o.route
                    break
                }

                case selectedModule.routes.viewPatient:
                case selectedModule.routes.editPatient:
                case selectedModule.routes.ptIndividual: {
                    element = <PatientDetailsInterface />
                    routeName = o.route
                    break
                }

                case selectedModule.routes.careprofessionalDashboard:
                case selectedModule.routes.ptReadinessDashboard:{
                    element = <CareProfessionalsDashboard/>
                    routeName = o.route
                    break
                }

                default:
                    break
            }

            element && arr.push({
                moduleName: found.moduleName,
                path: routeName,
                element
            })
        })
    }

    return arr
}

export default Routes
