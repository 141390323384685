import { useAppSelector } from '@app/app.hook'
import { selectStrings } from '@app/slices/slice.app'
import FunnelChart from '@doc/components/careprofessionalsDashboard/ptReadinessMain/FunnelChart'
import MarkdownTextField from '@doc/components/careprofessionalsDashboard/ptReadinessMain/MarkdownTextField'
import ReadinessTable from '@doc/components/careprofessionalsDashboard/ptReadinessMain/ReadinessTable'
import ScatterChart from '@doc/components/careprofessionalsDashboard/ptReadinessMain/ScatterChart'
import {
    GetReadinessChartResponse,
    GetReadinessFunnelResponse,
    GetReadinessTableResponse,
    ReadinessTableActions,
    ReadinessTableState
} from '@doc/type'
import { useRef } from 'react'
import { DebouncedState } from 'use-debounce'

interface ComponentProps {
    getReadinessChartResponse: {
        isLoading: boolean
        isSuccess: boolean
        response: GetReadinessChartResponse | undefined
    }
    readinessAdvisoryData: {
        isLoading: boolean
        isSuccess: boolean
        data: {
            advisoryData: {
                header: string;
                body: string;
            };
        } | undefined
    }
    getReadinessFunnelResponse: {
        isLoading: boolean
        isSuccess: boolean
        response: GetReadinessFunnelResponse | undefined
    }
    readinessTableState: ReadinessTableState
    readinessTableDispatch: React.Dispatch<ReadinessTableActions>
    debouncedFetchReadinessTable: DebouncedState<() => void>
    getReadinessTableResponse: {
        isLoading: boolean
        isSuccess: boolean
        response: GetReadinessTableResponse | undefined
    }
}

const PTReadinessMain = (
    {
        getReadinessChartResponse,
        readinessAdvisoryData,
        getReadinessFunnelResponse,
        readinessTableState,
        readinessTableDispatch,
        debouncedFetchReadinessTable,
        getReadinessTableResponse
    }:ComponentProps
) => {
    const rootRef = useRef<HTMLDivElement | null>(null)
    const strings = useAppSelector(selectStrings)

    const LoadingContent = (
        <small className={'d-block text-center py-2'}>
            <div className={'spinner-container'}>
                <span className={'spinner-border spinner-border-sm'}></span>
                <span className={'ms-2'}>{
                    strings.app?.text.loading || ''
                }</span>
            </div>
        </small>
    )

    return <div>
        <div className={'row row-cols-2 g-3 mb-5'}>
            <div className={'col'}>
                {
                    getReadinessChartResponse.isLoading
                        ? LoadingContent
                        : getReadinessChartResponse.isSuccess
                            ? <ScatterChart getReadinessChartResponse={getReadinessChartResponse.response}/>
                            : ''
                }

            </div>
            <div className={'col'}>
                {
                    readinessAdvisoryData.isLoading
                        ? LoadingContent
                        : readinessAdvisoryData.isSuccess
                            ? <MarkdownTextField readinessAdvisoryData={readinessAdvisoryData.data}/>
                            : ''
                }
            </div>
            <div className={'col'}>
                {
                    getReadinessFunnelResponse.isLoading
                        ? LoadingContent
                        : getReadinessFunnelResponse.isSuccess
                            ? <FunnelChart
                                getReadinessFunnelResponse={getReadinessFunnelResponse.response}
                                readinessTableDispatch={readinessTableDispatch}
                            />
                            : ''
                }
            </div>
            <div>
                {<ReadinessTable
                    debouncedFetchReadinessTable={debouncedFetchReadinessTable}
                    rootRef={rootRef}
                    getReadinessTableResponse={{
                        isLoading: getReadinessTableResponse.isLoading,
                        isSuccess: getReadinessTableResponse.isSuccess,
                        response: getReadinessTableResponse.response,
                        readinessTableState,
                        readinessTableDispatch
                    }}
                />}
            </div>
        </div>
    </div>
}

export default PTReadinessMain
