/* eslint-disable max-len */
/* eslint-disable indent */
import { MOBILE_RESPONSIVE_LIMIT, SIDEBAR_WIDTH } from '@app/app.constants'
import { css, useTheme } from '@emotion/react'
const GlobalStyle = () => {
    const theme = useTheme()

    return css`
    //needs to be global for modals to be used here.
    .chart-container {
        position: relative;
        width: 100%;
        /** disable maintainAspectRatio on chartjs if this is the case. */
        height: 250px;

        .viewDetails {
            color: ${ theme.config.admin?.careprofDashboard
                        .careprofessionalHeader.greetings.subtitle.urgentColor };
            font-weight: 600;
        }
    }

    .careprofessional-dashboard-page {

        height: 100%;
        position: absolute;

        /** anything that has a default size from the body tag will use this instead */
        font-size: 14px;

        /** form-control included */
        .form-control {
            font-size: 14px;
        }

        @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
            width: 100%;
        }

        @media (min-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
            width: calc(100% - ${ [SIDEBAR_WIDTH, 'px'].join('') });
        }

        //placed search-box here because some other components share the same css properties
        //besides the width.
        .search-box {
                
            &:focus {
                border-color: ${ theme.config.admin
                    ?.careprofDashboard.searchBox.focus.borderColor };
                outline: 0;
                box-shadow: 0 0 0 0.25rem ${ theme.config.admin
                    ?.careprofDashboard.searchBox.focus.boxShadow };
            }

            display: flex;
            align-items: center;
            background-color: ${ theme.config.admin?.careprofDashboard.searchBox.background };
            border-radius: 16px;
            padding: 10px 20px;
            height: 40px;

            input {
                font-weight: 300;
                padding: 0;
                background: inherit;
                :focus {
                    outline: none;
                    box-shadow: none;
                    border: 0;
                }
            }

            @media (max-width: 767px) {
                input {
                    width: 100%;
                }
            }

            i {
                color: ${ theme.config.admin?.careprofDashboard.searchBox.icon.textColor };
                margin-right: 10px;
            }
        }
        
        .careprofessional-header {
            color: ${ theme.config.admin?.careprofDashboard.careprofessionalHeader.color };

            /* height: 220px; */
            height: 100px;
            width: 100%;
            background: ${ (() => {
                const gradientColors = theme.config.admin?.careprofDashboard
                .careprofessionalHeader.gradientColors
                if (gradientColors && gradientColors.length >= 3) {
                    return `linear-gradient(180deg, ${ gradientColors[0] } 9.04%, ${ gradientColors[1] } 65.47%, ${ gradientColors[2] } 91.12%)`
                } else {
                    return 'transparent' // fallback if gradientColors is not properly defined
                }
            })() };

            @media (max-width: ${ MOBILE_RESPONSIVE_LIMIT }) {
                height: auto;
                position: fixed;
                top: 0;
                z-index: 2;
            }

            .icon-container {
                cursor: pointer;
                font-size: 0.9em;
                color: ${ theme.config.admin?.careprofDashboard.careprofessionalHeader
                .iconContainer.color };
                background-color: transparent;
                border: 2px solid ${ theme.config.admin?.careprofDashboard
                .careprofessionalHeader.iconContainer.borderColor };
                border-radius: 16px;
                padding: 10.25px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .search-box {
                width: 400px; // Adjust width as needed
            }

            .greetings {
                text-align: center;

                .title {
                    font-size: 1.7em;
                    margin-block-start: 0.4em;
                    color: ${ theme.config.admin?.careprofDashboard
                    .careprofessionalHeader.greetings.title.color };
                }

                .subtitle {
                    color: ${ theme.config.admin?.careprofDashboard
                    .careprofessionalHeader.greetings.subtitle.color };
                    font-weight: lighter;
                    font-size: 0.9em;

                    &.urgent {
                        color: ${ theme.config.admin?.careprofDashboard
                        .careprofessionalHeader.greetings.subtitle.urgentColor };
                        font-weight: 500;
                    }
                }
            }
        }

        /** why? because div id=root doesn't have a height property assigned.
        doing 100vh will not work as you are assigning it the current screen size.
        even so, you need to assign position:absolute to avoid relying on a parent element
         */

        & .navigation-container {
            display: flex;
            height: 38px;
            border-radius: ${ theme.config.admin?.careprofDashboard.navigationContainer.borderRadius };
            padding-right: 4rem;
            padding-left: 4rem;

            & .navigation-tab {
                color: ${ theme.config.admin?.careprofDashboard.navigationContainer.tab.color };
                width: 180px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                cursor: pointer;
                border-bottom: 2px solid transparent;
                font-size: 12px;
                box-shadow: ${ theme.config.admin?.careprofDashboard.navigationContainer
                .tab.boxShadow };
                position: relative;
                z-index: 1;
                background: ${ theme.config.admin?.careprofDashboard.navigationContainer
                .tab.background };
                border-radius: ${ theme.config.admin?.careprofDashboard.navigationContainer
                .tab.borderRadius };
                margin-inline: 0.5em;

                &.active-tab {
                    background: ${ theme.config.admin?.careprofDashboard.navigationContainer
                    .tab.activeBackground };
                    font-weight: 600;
                    color: ${ theme.config.admin?.careprofDashboard.navigationContainer
                    .tab.activeColor };  
                }
            }
        }


        & .main-content {

            width: 100%;
            
            //then apply a min-height so you can scroll down with the height increasing as more 
            //content grows.
            min-height: calc(100% - 60px);
            color: ${ theme.config.admin?.careprofDashboard.mainContent.textColor };
            background: ${ (() => {
                const gradientColors = theme.config.admin?.careprofDashboard.mainContent.gradientColors
                if (gradientColors && gradientColors.length >= 2) {
                    return `linear-gradient(153.58deg, ${ gradientColors[0] } 0%, ${ gradientColors[1] } 100%)`
                } else {
                    return 'transparent' // fallback if gradientColors is not properly defined
                }
            })() };

            .dropdown-list{
                .search-box {
                    background-color: ${ theme.config.admin?.careprofDashboard
                    .dropdownList.searchBox.backgroundColor };
                }
            }

            .abnormal-interface,
            .readiness-interface,
            .treated-interface,
            .arriving-interface {
                /* overflow-y: auto; */
                border-radius: 18px;
                border: 2px solid ${ theme.config.admin?.careprofDashboard.mainContent.interfaces.borderColor };
                padding: 1em 2em 2em 2em;
                background: ${ theme.config.admin?.careprofDashboard.mainContent.interfaces.background };
                box-shadow: 0px 5px 15px 0px ${ theme.config.admin?.careprofDashboard.mainContent.interfaces.boxShadow };

                .img-wrapper {
                    position: relative;
                    width: 220px;

                    .rounded-square {
                        border-radius: 18px;
                    }

                    .img-placeholder,
                    .profile {
                        background: ${ theme.config.admin?.careprofDashboard.profileImgPlaceholder.background };;
                        width: 220px;
                        height: 220px;
                    }
                }

                .img-wrapper .btn {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }

                .search-box {
                    max-width: 550px;
                    width: 100%;
                }

                .record-menu {
                    height: 620px;

                    tr {
                        height: 55px;
                    }
                }

                &.hcCards {
                    border: 0px solid ${ theme.config.admin?.careprofDashboard.mainContent.interfaces.hcCards.borderColor };

                    .chart-bordered {
                        border-bottom-right-radius: 1rem !important;
                        border-bottom-left-radius: 1rem !important;
                    }

                    .title {
                        line-height: 1.2em;
                        min-height: 2.4em;
                    }

                    .current-number {
                        font-size: 3rem;
                    }

                    .lt {
                        color: ${ theme.config.admin?.careprofDashboard.mainContent.interfaces.hcCards.ltColor };
                    }

                    .gt {
                        color: ${ theme.config.admin?.careprofDashboard.mainContent.interfaces.hcCards.gtColor };
                        font-size: 1.2em;
                        font-weight: 500;
                    }
                }
            }

            .record-menu {
                /* CSS Trick Explanation:
                Imagine a suitcase that starts with no height (height: 0px).
                As you add clothes (content) to the suitcase, it magically expands (flex: 1 1 auto)
                to fit them, but it won't exceed a certain height (container's height).
                When you try to put too much, you need to close the suitcase (scrolling).
                */
                /* height: 0px; */
                //UPDATE: 9/15/2023: As sgguested by Tim
                /* height: auto;
                flex: 1 1 auto; */

                //REMOVED flex column to regular.
                // max height will be calculated in react.
                /* max-height: 600px; */

                /* overflow-y: auto; */
                border-radius: 16px;
                border: 2px solid ${ theme.config.admin?.careprofDashboard.mainContent.recordMenu.borderColor };
                padding: 0;

                overflow-x: auto;

                table {
                    border-collapse: collapse; /* Merge table borders */
                    width: 100%; /* Make the table full-width */

                    input[type="checkbox"]:not([role="switch"]) {
                        width: 20px;
                        height: 20px;
                        border-radius: 3px;
                        border: 2px solid ${ theme.config.admin?.careprofDashboard.mainContent.recordMenu.tableBorderColor };

                        &:focus-visible {
                            outline: 0;
                        }
                    }
                }

                tr {
                    cursor: pointer;

                    &.accordion{
                        cursor: default;

                        & .button-container {
                            & button {
                                font-weight: 600;
                            }
                        }
                    }
                }

                th, td {
                    text-align: left;
                    padding: 8px; /* Add padding to table cells */
                }

                th {
                    font-weight: 600;
                    white-space: nowrap;
                    padding-block: 14px;
                    padding-inline: 14px;
                    background-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.recordMenu.tableOddRowBackground }; /* Background color for table header */
                    border-bottom: 1px solid ${ theme.config.admin?.careprofDashboard
                    .mainContent.recordMenu.tableBorderColor };
                }

                td {
                    white-space: nowrap;
                    padding-block: 8px;
                    padding-inline: 8px;
                    font-weight: 300;
                    border-bottom: 1px solid ${ theme.config.admin?.careprofDashboard
                    .mainContent.recordMenu.tableBorderColor };
                }

              /* Style even rows with a white background */
                tbody tr:nth-of-type(4n-1), /* Selects row 1, 5, 9, 13, ... */
                tbody tr:nth-of-type(4n) { /* Selects row 2, 6, 10, 14, ... */
                    background-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.recordMenu.tableEvenRowBackground };
                }

                tbody tr:nth-of-type(4n-3), /* Selects row 3, 7, 11, 15, ... */
                tbody tr:nth-of-type(4n-2) { /* Selects row 4, 8, 12, 16, ... */
                    background-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.recordMenu.tableOddRowBackground };
                }

            }

            .cells {

                display: flex;
                div {
                    border: 0.5px solid ${ theme.config.admin?.careprofDashboard.mainContent.cells.borderColor };
                    padding: 2px 9px; /* top right bottom left */
                    border-radius: 18px;
                    margin: 5px; /* Adjust spacing between cells as needed */
                }
            }

            .risk-border {
                border-left-width: 3px;
                border-left-style: solid;
                border-color: transparent;
                border-left-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.riskBorder.borderColor };

                &.green {
                    border-left-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.riskBorder.greenColor };
                }

                &.yellow {
                    border-left-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.riskBorder.yellowColor };
                }

                &.orange {
                    border-left-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.riskBorder.orangeColor };
                }

                &.red {
                    border-left-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.riskBorder.redColor };
                }

                &.grey {
                    border-left-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.riskBorder.greyColor };
                }
            }

            & .progress {
                height: 8px;
                min-width: 80px;
            }

            & .progress-bar {
                
                background-color: ${ theme.config.admin?.careprofDashboard
                    .mainContent.progressBar.background };
            }


            .risk-container {
                padding: 5px 9px 5px 9px;
                font-size: 15px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                border-width: 1px;
                border-style: solid;
                border-color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.borderColor };

                &.green {
                    border-color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.greenColor };
                    color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.greenColor };
                }

                &.yellow {
                    border-color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.yellowColor };
                    color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.yellowColor };
                }

                &.orange {
                    border-color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.orangeColor };
                    color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.orangeColor };
                }

                &.red {
                    border-color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.redColor };
                    color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.redColor };
                }

                &.grey {
                    border-color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.greyColor };
                    color: ${ theme.config.admin?.careprofDashboard?.mainContent?.riskContainer.greyColor };
                }

                .fraction {
                    font-size: 1em;
                }

                .fraction span.numerator {
                    font-weight: 600;
                    padding-inline-end: 3px;
                }
            }

        }




    }
    `
}

export default GlobalStyle
