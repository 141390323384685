import { useAppSelector } from '@app/app.hook'

import { ADMIN_COLORS as IKH_ADMIN_COLORS } from '@stylesheet/brands/ikh/admin/Colors'
import { ADMIN_COLORS as RTW_ADMIN_COLORS } from '@stylesheet/brands/rtw/admin/Colors'
import { selectStylesheets } from '@stylesheet/slice'
import { Chart, ChartOptions, LegendOptions, TitleOptions, TooltipOptions } from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Options } from 'chartjs-plugin-datalabels/types/options'
import { useEffect, useRef } from 'react'

import { selectToken } from '@app/slices/slice.token'
import { GetReadinessChartResponse } from '@doc/type'
import Color from 'color'
import _ from 'lodash'

type ActiveChartType = Chart<'scatter', {x:number, y:number}[]>;

interface ComponentProps {
    getReadinessChartResponse: GetReadinessChartResponse | undefined
}

// we just need the data
const ScatterChart = ({ getReadinessChartResponse }: ComponentProps) => {
    // Reference to the canvas element where the chart will be rendered
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    // Store the chart instance in a ref for later access and manipulation
    const chartInstanceRef = useRef<ActiveChartType | null>(null)

    const stylesheets = useAppSelector(selectStylesheets)
    const token = useAppSelector(selectToken)

    let sheetToUse = IKH_ADMIN_COLORS

    if (_.includes(token.details.ss?.admin, 'ikh-admin')) {
        sheetToUse = IKH_ADMIN_COLORS
    } else if (_.includes(token.details.ss?.admin, 'rtw-admin')) {
        sheetToUse = RTW_ADMIN_COLORS
    }

    useEffect(() => {
        // Data and options for the chart

        const legendPlugin: | _DeepPartialObject<LegendOptions<'scatter'>> | undefined = {
            display: false
        }

        const titlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false

        }

        const subtitlePlugin: _DeepPartialObject<TitleOptions> | undefined = {
            display: false
        }

        const tooltipPlugin: | _DeepPartialObject<TooltipOptions<'scatter'>>| undefined = {
            enabled: true
        }

        const dataLabelsPlugin: _DeepPartialObject<Options> | undefined = {
            display: false
        }

        const options: ChartOptions<'scatter'> = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: legendPlugin,
                title: titlePlugin,
                subtitle: subtitlePlugin,
                tooltip: tooltipPlugin,
                datalabels: dataLabelsPlugin

            },
            layout: {
                autoPadding: false
                // padding: -10
            },
            scales: {
                x: {
                    type: 'linear',
                    position: 'bottom'
                }
            }
        }
        // Create the chart when the component mounts
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')

            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'scatter',
                    data: {
                        datasets: [
                            {
                                data: getReadinessChartResponse?.data.readinessData || [],
                                backgroundColor: function (context) {
                                    const index = context.dataIndex
                                    const value = (getReadinessChartResponse?.data.readinessData || [])[
                                        index
                                    ]

                                    if (value) {
                                        const gradientRatio = (value.y + value.x) / 20 // Map y-value to range [0, 1]
                                        return Color(sheetToUse.orange)
                                            .mix(Color(sheetToUse.blue), gradientRatio)
                                            .hex() // Return the color as hex
                                    }

                                    return 'black'
                                },
                                pointStyle: 'rect'
                            }
                        ]
                    },
                    options
                })
            }
        }

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy()
            }
        }
    }, [stylesheets, token.details.locale, getReadinessChartResponse])

    return <div style={{
        height: 500
    }} className={'readiness-interface'} >
        <canvas className={'chart-bordered'}
            ref={canvasRef} />
    </div>
}

export default ScatterChart
